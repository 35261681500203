.Container {
  height: 100%;
  width: 100%;
  overflow-y: auto;
  max-height: 500px;
  margin: 2%;
  z-index: 1;
  scrollbar-color: #d4aa70 #e4e4e4;
  scrollbar-width: thin;

  .react-json-view {
    margin: 3%;
    scrollbar-color: #d4aa70 #e4e4e4;
  }

  &::-webkit-scrollbar {
    width: 15px;
  }

  &::-webkit-scrollbar-track {
    background-color: #ecf0d1;
    border-radius: 100px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 100px;
    background-image: linear-gradient(
      180deg,
      #f5ffb1 0%,
      rgb(245, 255, 169) 99%
    );
    box-shadow: inset 2px 2px 5px 0 rgba(#fff, 0.5);
  }

  &::-webkit-scrollbar-thumb:hover {
    background-image: linear-gradient(
      180deg,
      #eeff7e 0%,
      rgb(232, 252, 87) 99%
    );
  }
}
