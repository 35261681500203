@import "../../styles/variables";

%btn {
  margin-top: 1%;
  width: 15%;
  min-width: 100px;
  float: right;
  border-radius: 15px;
  &:hover {
    background: $secondary-color !important;
    color: $primary-color !important;
  }
}

.Container {
  height: auto;
  overflow: hidden;
  margin-top: -10%;

  .content {
    color: $secondary-color;
    font-family: "Merriweather", serif;
    text-align: center;
    padding: 3%;
    width: 100%;

    .inputs {
      width: 100%;
      margin: auto;
      max-width: 500px;

      .input {
        padding: 2px;
      }
    }

    .Btn {
      @extend %btn;
      background-color: $primary-color;
      color: $secondary-color;
    }
    .BtnDisabled {
      @extend %btn;
      background-color: $secondary-color;
      color: $primary-color;
    }

    .Title {
      color: $primary-color;
      font-family: "Lato", sans-serif;
      font-size: 2rem;
      font-weight: 300;
      line-height: 10px;
      margin-bottom: 35px;
    }
  }
}
