@import "./styles/variables";

.MessageContainer {
  position: absolute;
  top: 0; 
  height: 100%;
  width: 100%;
  z-index: 10000000000000;

  .Message{
    background-color: $primary-color;
    color: $secondary-color;
    opacity: 0.8;
    font-size: large;
    font-weight: 500;
  }
}

.reactLockScreen {
  filter: blur(10px);
}

.SpinnerContainer{

  margin: auto;
  padding: 0;
  width: 50%;
  margin-top: 25%;
  display: table;

  .Spinner{
    display: table-cell;
    color: $secondary-color;
  }
}

