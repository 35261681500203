.Modal {
  width: 40% !important;
  margin-right: auto;
}

@media only screen and (max-width: 750px) {
  .Modal {
    width: 60% !important;
  }
}

@media only screen and (max-width: 650px) {
  .Modal {
    width: 70% !important;
  }
}

@media only screen and (max-width: 500px) {
  .Modal {
    width: 90% !important;
  }
}
