@import "../../styles/_variables.scss";

%btn {
  background: $secondary-color !important;
  color: $primary-color !important;
  &:hover {
    background: $primary-color !important;
    color: $secondary-color !important;
  }
}

.Container {
  height: 8vh;
  background-image: linear-gradient(180deg, $primary-color 0%, $primary-color 99%);
  width: 100%;
  box-shadow: 0 5px 4px 0 rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: space-between;
  overflow: hidden;

  .logo {
    margin: auto 2%;
    height: 80%;
    &:hover {
      cursor: pointer;
    }
  }

  .flag {
    height: 100px;
  }

  .RightChild {
    display: flex;
    width: 15%;
    min-width: 200px;
    justify-content: space-around;

    .LogInBtn {
      @extend %btn;
      width: auto;
      margin: auto 1%;
    }

    .User {
      max-width: 50px;
      min-width: 50px;
      background-size: 100% 100%;
      background-repeat: no-repeat;
      border-radius: 50%;
      margin: auto 1%;
      height: 70%;
      &:hover {
        cursor: pointer;
      }
    }

    .GlobeIcon {
      text-align: center;
      margin: auto 0;
      &:hover {
        cursor: pointer;
      }
    }
  }
}

.LogOutBtn {
  @extend %btn;
  width: auto;
  margin-left: 10%;
}
