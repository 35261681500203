@import "../../styles/_variables.scss";

.Container {
  height: auto;
  background-color: $primary-color;
  text-align: center;
  width: 100%;

  .message {
    color: $secondary-color;
    font-size: 1.1em;
    font-weight: 500;
    line-height: 2;
    margin: auto;
  }
}
