@import "../../styles/variables";

.Container {
  overflow: hidden;
  width: 100%;
  height: 92vh;

  .MapContainer {
    overflow: hidden;
    min-height: 100%;
  }

  .Sider {
    background: $secondary-color;
    .SiderChildren {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      height: 100%;

      & > * {
        width: 80%;
        margin: auto;
      }

      .Button {
        background: $primary-color;
        color: $secondary-color;
        font-size: 1rem;
        font-weight: 500;
        &:hover {
          background: $secondary-color !important;
          color: $primary-color !important;
          cursor: pointer;
        }
      }

      .Link {
        width: auto;
        font-size: 1.5rem;
        color: $primary-color;
        margin-left: auto;
        &:hover {
          cursor: pointer;
          text-decoration: underline;
        }
      }
    }
  }

  .Header {
    width: auto;
    background-color: transparent;
    position: absolute;
    z-index: 1;

    .icon {
      padding: 0;
      font-size: 2rem;
      line-height: 64px;
      cursor: pointer;
      transition: color 0.3s;
      &:hover {
        color: $primary-color;
      }
    }
  }
}
